import React from "react"
import "bulma/css/bulma.css"
import "../styles/footer.scss"
import { withStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import PhoneIcon from "@material-ui/icons/Phone"
import RoomIcon from "@material-ui/icons/Room"
import AccessTimeIcon from "@material-ui/icons/AccessTime"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import AttachFileIcon from "@material-ui/icons/AttachFile"
import logo from "../assets/logo-white.png"
import pdf from "../assets/regulamin-konsultacja-online.pdf"

const styles = () => ({
  notchedOutline: {},
  focused: {
    "& $notchedOutline": {
      borderColor: "yellow",
    },
  },
  input: {
    color: "#FEFEFE",
    padding: "5px",
  },
  floatingLabelFocusStyle: {
    color: "#FEFEFE",
  },
  root: {
    "&:hover": {
      backgroundColor: "#B86C90",
    },
    marginTop: "15px",
    float: "right",
    backgroundColor: "#92497E",
    color: "#FAFAFA",
  },
})
const Footer = props => {
  const { classes } = props
  return (
    <footer className="footer footer-background">
      <form
        action={"/sukces"}
        name="form-kontakt-footer"
        method="post"
        data-netlify-honeypot="bot-field"
        data-netlify={true}
      >
        <input type="hidden" name="form-name" value="form-kontakt-footer" />
        <input type="hidden" name="bot-field" />
        <div className="footer-wrapper">
          <div className="infobox-container">
            <img className="logo-biale-footer" src={logo} alt="laser-medica-logo" />
            <div>
              <div className="flex-row-center">
                <PhoneIcon style={{ margin: "6px" }} />
                <span>
                  tel.{" "}
                  <a style={{ textDecoration: "underline", color: "#FAFAFA" }} href="tel:914334852">
                    +48 91 433 48 52
                  </a>
                </span>
              </div>
              <div className="flex-row-center">
                <RoomIcon style={{ margin: "6px" }} />
                <span>ul. Marsz. J. Piłsudskiego 21, 70-462 Szczecin</span>
              </div>
              <div className="flex-row-center">
                <AccessTimeIcon style={{ margin: "6px" }} />
                <span>czynne: pn-pt 9.00-20.00, sob 9.00-14.00</span>
              </div>
              <div className="flex-row-center">
                <AttachFileIcon style={{ margin: "6px" }} />
                <a style={{ color: "#FFF" }} href={pdf} target="_blank" rel="noreferrer">
                  Regulamin Laser-Medica
                </a>
              </div>
            </div>
          </div>
          <div className="contact-form-container">
            <div className="form-name-email">
              <TextField
                InputLabelProps={{ className: classes.floatingLabelFocusStyle }}
                InputProps={{ className: classes.input }}
                style={{ margin: "5px" }}
                id="standard-basic"
                name="imie"
                type="text"
                label="Imię"
              />
              <TextField
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                InputProps={{ className: classes.input }}
                style={{ margin: "5px" }}
                id="standard-basic"
                name="email"
                type="email"
                label="Email"
              />
            </div>
            <div className="te">
              <TextField
                fullWidth
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                InputProps={{ className: classes.input }}
                id="filled-multiline-flexible"
                multiline
                rows={4}
                rowsMax={10}
                type="text"
                name="wiadomosc"
                label="Wiadomość"
              />
            </div>
            <Button
              className={classes.root}
              variant="contained"
              size="large"
              type="submit"
              endIcon={<ArrowForwardIcon />}
            >
              Wysyłam
            </Button>
          </div>
        </div>
      </form>
    </footer>
  )
}
export default withStyles(styles)(Footer)
