import React from "react"
import "bulma/css/bulma.css"
import '../styles/sukces.scss'
import {Link} from 'gatsby'
import Footer from "../components/footer"
import _navigation from "../components/_navigation"
import paperPlane from "../assets/paper-plane.svg"
const Sukces = () => {
  return (
    <div>
      <_navigation/>
      <section className="hero hero-promocje-background">
        <div className="hero-body has-text-centered">
          <div className="container">
            <h1 className="title promocje-tytul">
              Wiadomość wysłana
            </h1>
          </div>
        </div>
      </section>
      <section className="container sukces-wrapper" >
        <div className="paper-plane" >
          <img src={paperPlane} alt=""/>
        </div>
        <div className="tekst-sukces" style={{marginBottom: "20px"}}>
          Dziękujemy za skorzystanie z formularza kontaktowego. <br/>
          <span className="podpis-zespol">- zespoł Laser-Medica.</span>
        </div>
        <Link to={"/"}>Powrót do strony głównej</Link>
      </section>
      <Footer/>
    </div>
  )
}
export default Sukces
